@import '../../node_modules/materialize-css/dist/css/materialize.css';
@import './style_guide.styl';
*
  margin: 0
  padding: 0
  font-family 'Muli', sans-serif
  font-size: 16px
  color: #333333
  outline none

a
  color color_main

.btn
  color #fff !important

.container
  max-width 90%
  margin 0 auto

.rules
  margin-top 30px
  .rule_save,
  .rule_delete
    opacity: 0.7
    transition: 0.2s
    margin-top 7px
    display block
    &:hover
      opacity 1
      cursor pointer
  .rule_items
    transition all .1s ease
    padding 5px 10px
  .rule_item
    margin 20px 0
  .rule_title
    color #333 !important
    font-size 16px !important
    margin-bottom 20px
    font-weight 600
    top -5px
    &.rule_title_select
      top -32px
    &-wrap
      display flex
      justify-content space-between
      border 1px solid #d3dae0
    width calc(100% - 30px)
  .rule
    margin-bottom 20px
    &_checkbox-container
      margin-left 35px
      .input-field>label
        color grey
    &-title
      font-size 1.15rem !important
      cursor pointer
      .rule-title_alltime
        span
          display inline-block
          &:not(:last-child)
            margin-right 5px
      .rule-title_time
        height 30px
        line-height 30px
        span
          display inline-block
      .rule-title_days
        display flex
        margin-top 3px
        div
          width 5px
          height 5px
          font-size 0px
          line-height 0px
          background-color color_grey
          margin 0 3px
          &.active
            background-color color_action
      .row
        margin-bottom 0px !important
      span
        line-height 42px
    &:last-child
      margin-bottom 0px

.rule_input-field
  margin 5px 0

.source_btn
  margin 30px 10px 30px 0

textarea
  padding: 8px 5px

.rule_weeks_days
  display flex
  div
    border-radius 3px
    border 1px solid #d3dae0
    padding 5px
    margin 0 5px
    width 36px
    height 36px
    text-align center
    background-color #f1f1f1
    cursor pointer
    &:nth-child(1)
      margin-left 0px
    &:nth-child(2)
      margin-right 0px
    &.day_active
      background-color color_action
      color #fff

.fields
  &-wrap
    display flex
    justify-content space-between
  &-item
    display flex
    flex-direction column
    &.end
      justify-content flex-end
  &-item:not(:last-child)
    margin-right 10px
  &-item:nth-child(1),
  &-item:nth-child(2)
    width 45%

.field_btn
  cursor pointer
  width 30px
  height 100%
  border-left 1px solid #d3dae0

.m-5
    margin-bottom 5px
.m-10
  margin-bottom 10px

.m-t-30
  margin-top 30px

.m-t-40
  margin-top 40px

.m-t-45
  margin-top 45px

.full-height
  height 100%


.source_list
  margin-top 50px
  border none
  &-item
    display flex
    justify-content space-between
    align-items center
    border: 1px solid #e0e0e0
    &:last-child
      border-bottom 1px solid #e0e0e0 !important
    & + *
      border-top none
  &-title
    font-size 16px
    width 30%
    &:hover
      color #26a69a
      cursor pointer

header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}

.main-wrap
  padding 30px

.main-container
  padding 30px

/*
 *
 *
 *
 */

html
  font-family 'Muli', sans-serif !important
  font-weight normal
  line-height 1.5
  color #6b6f82

.card
  .card-title
    font-size 18px
    font-weight 400
    margin 0
    span
      margin 0 5px

.breadcrumbs
  display flex
  margin-bottom 10px
  li
    display inline-block
    list-style none
    position relative
    &:not(:last-child)
      padding-right 15px
      &:after
        position absolute
        content '/'
        right 5px
        color rgba(0,0,0,0.6)

.section-header
  font-weight 700
  line-height 2.25rem
  margin 0px
  i
    font-size: 2.3rem
    vertical-align: bottom

.table, .form-info
  td:not(:last-child)
    width calc(20% - 10px)
  td:last-child,
  th:last-child
    width 50px
  thead
    border-top 1px solid rgba(0,0,0,.12)
    th
      padding 8px 14px
      white-space nowrap
      border none !important
  tbody
    border-color rgba(0, 0, 0, .12)
    tr:nth-child(even)
      background-color #ffffff
    tr:nth-child(odd)
      background-color #f4f5f7
    td
      padding 8px 14px
      white-space nowrap
      border none !important
      a
        color_main
  &_delete
    display inline-block
    cursor pointer
  &_actions
    display flex
    justify-content flex-start
    align-items center
    a:not(:last-child)
      margin-right 10px
    i
      color #9e9e9e
  .table-separate-titles
    //display flex
    .table-separate-title
      margin-right 25px
      color inherit

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 14.5px;
  }
}

@media only screen and (min-width: 0) {
  html {
    font-size: 14px;
  }
}

.fields_title,
input:focus + .fields_title
  color #9e9e9e
  margin-bottom 20px

.m-r-20
  margin-right 20px

.m-t-20
  margin-top 20px

.input-field>label:not(.label-icon).active
  transform translateY(-14px) scale(1)

.input-field input:focus,
.input-field textarea:focus
  border-bottom 1px solid color_main !important
  -webkit-box-shadow 0 1px 0 0 color_main !important
  box-shadow 0 1px 0 0 color_main !important
  & + label
    color #333 !important


  //todo : for fix textarea
.input-field textarea.materialize-textarea
  resize vertical!important
  min-height 100px!important

[type=checkbox].filled-in:checked+span:not(.lever):after
  border 2px solid color_main
  background-color color_main

.card
  .card-filter
    position relative
    i
      position absolute
      top 13px
      left 10px
      color #bdbdbd
    .filter_search
      max-width -webkit-calc(100% - 3rem)
      max-width    -moz-calc(100% - 3rem)
      max-width         calc(100% - 3rem)
      margin-bottom .5rem
      padding .2rem
      padding-left 2.75rem
      border none
      border-radius .4rem
      background-color #fff
      -webkit-box-shadow 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2)
      box-shadow 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2)
      &:focus
        -webkit-box-shadow 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2) !important
        box-shadow 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2) !important
        border-bottom 0px !important

.card-main
  max-width 820px

.fields_inner,
.rules_inner
  display flex

.switch
  label
    .lever
      height 20px
      margin 0px
      &:before,
      &:after
        top 0px
    input[type=checkbox]:checked
      & + .lever
        background-color color_action
        &:after
          background-color #f1f1f1

.input-field>label:not(.label-icon).active
  transform translateY(-14px) scale(1)

[type=checkbox].filled-in:checked+span:not(.lever):after
  border 2px solid color_action
  background-color color_action

textarea.materialize-textarea
  height 100px !important
  border 1px solid grey !important
  padding .4rem
  overflow auto
  &:focus
    border-top 1px solid grey
    border-right 1px solid grey
    border-left 1px solid grey
  & + label
    top -15px !important

.btn-floating
  width 24px
  height 24px
  position absolute
  bottom 24px
  right 0px
  background-color #9e9e9e
  user-select none
  &:hover
    background-color color_main
  i
    line-height 24px
    font-size 1.4rem
    display block

h1
  margin 0px
  margin-bottom 30px
  font-size 32px
  line-height 44px

.logo
  padding 20px 16px
  border-bottom: 1px solid rgba(0,0,0,0.14);
  h5
    margin 0px
    font-size 20px
  &:hover
    background none

.sidenav .collapsible-header:after
  font-family 'Material Icons'
  font-size 18px
  position absolute
  right 16px
  content 'keyboard_arrow_right'
  transition transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out
  color #afafaf

.sidenav li.active > .collapsible-header:after
  -webkit-transform rotate(90deg)
  -moz-transform rotate(90deg)
  -ms-transform rotate(90deg)
  -o-transform rotate(90deg)
  transform rotate(90deg)

.sidenav
  li
    a
      color color_main

.sidenav-active-rounded .sidenav .collapsible-header:after
  right: 9px;

.sidenav li a.active
  background: #0288d1;
  -webkit-box-shadow: none;
  box-shadow: none;

.router-link-exact-active
  background-color color_main
  color #fff !important
  i
    color #fff !important

.sidenav li > a > i.material-icons,
.sidenav li a.collapsible-header > i.material-icons
  line-height: 48px;
  height: 48px;
  margin-right 0px

.sidenav li > a > i.material-icons
  float: left;
  width: 24px;

.sidenav li .collapsible-body a i
  font-size: .7rem;
  color color_main

.sidenav li>a:hover
  background-color color_main
  color #fff !important

.sidenav
  box-shadow none
  border-right: 1px solid rgba(0,0,0,0.14);

.btn-border-cyan
  border 1px solid color_main
  color color_main !important
  i
    color color_main
.btn-border-green
  border 1px solid color_action
  color color_action !important
  i
    color color_main

.card-alert .card-content
  padding: 8px 15px 12px

.dropdown-content li>a,
.dropdown-content li>span
  color color_main !important

.rules .rule_title_select_inner
  color: gray !important
  font-size: 1rem !important
  font-weight 400

.select-wrapper
  input.select-dropdown
    width calc(100% - 24px)
    padding-right 24px
    text-overflow ellipsis
  .dropdown-content
    max-height 300px

.select-dropdown li.optgroup>span
  color: rgba(0,0,0,0.4) !important

.dropdown-content li>a, .dropdown-content li>span
  font-size: 16px;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 14px 16px;

.select-dropdown li.optgroup~li.optgroup-option
  padding-left: 0px !important

.m-b-0
  margin-bottom 0px

.m-t-0
  margin-top 0px

.input-field>label
  font-size 16px !important
  color #333

.card_checkbox-title
  font-size 18px !important
.card_checkbox
  margin-bottom 15px

.collapsible
  .collection-item-only
    background-color transparent
    border none
    line-height inherit
    height inherit
    padding 0 16px
    &.router-link-active
      background-color color_main

.column_center
  display flex
  &_vert
    align-items center
  &_horiz
    justify-content center

.text-overflow
  text-overflow ellipsis
  overflow hidden
  white-space nowrap

.close
  cursor pointer

.centered
  display flex
  justify-content center
  align-items center

.user_telegram_code
  margin-top 32px
  font-size 14px
  display flex
  align-items center
  position relative
  .code
    font-size 14px
    margin 0 10px
    cursor pointer
    border-bottom 1px dotted #000
  input
    opacity 0
    position absolute
    pointer-events none

.btn-flat.btn-flat[disabled],
.btn-flat.disabled
  color #b3b2b2!important
  border 1px solid #b3b2b2!important

.btn-flat
  &:hover
    background-color transparent !important

.code-alert
  position absolute
  color red
  white-space nowrap
  left 0px
  bottom -22px

.fade-enter-active,
.fade-leave-active
  transition opacity .5s

.fade-enter,
.fade-leave-to
  opacity 0

.btn:focus,
.btn:active
  background-color color_main !important

input::-webkit-input-placeholder
  color color_main_text
input::-moz-placeholder
  color color_main_text
input:-ms-input-placeholder
  color color_main_text
input:-moz-placeholder
  color color_main_text


.modal-mask
  position fixed
  z-index 9998
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0, 0, 0, .5)
  display table
  transition opacity .4s ease
.modal-wrapper
  display table-cell
  vertical-align middle
  position relative
  z-index 9999
.modal-container
  width 600px
  position relative
  margin 0px auto
  padding 20px 30px
  min-height 35px
  background-color #fff
  border-radius 2px
  box-shadow 0 2px 8px rgba(0, 0, 0, .33)
  transition all .2s ease
  font-family Helvetica, Arial, sans-serif
  &.modal-container-xl
    width 1200px
  h5
    margin-bottom 25px
  .modal-textarea
    resize vertical
  .close
    position absolute
    top 20px
    right 20px
.modal-default-button
  float right
  .modal-enter
    opacity 0
.modal-leave-active
  opacity 0
.modal-enter .modal-container,
.modal-leave-active .modal-container
  -webkit-transform scale(0.95)
  transform scale(0.95)

.form-info-table *
  font-size 12px
.form-info
  overflow-x auto